/* eslint-disable class-methods-use-this */
/* eslint-disable @typescript-eslint/explicit-member-accessibility */
import React from 'react';
import styled from '@emotion/styled';

import Layout from '../components/Layout';
import SEO from '../components/seo';
import Subheader from '../components/Subheader';
import moment from 'moment-timezone';
import { Counter } from '../components/Counter';
import { getTheme } from '../utility/theme';
import { Link } from 'gatsby';
import { Button } from '@material-ui/core';

const Row = styled.div`
  margin-bottom: 2rem;

  Button {
    margin-right: 1rem;
    margin-bottom: 1rem;
  }

`;

type LinkProps = {
  link: string;
  text: string;
}
const makeLinks = (arr: LinkProps[]) => {
  return arr.map((a, b) => {
    return (
      <Link to={a.link} key={a.link}>
        <Button variant="contained" color="primary">{a.text}</Button>
      </Link>
    )
  })
}
const LINKS_MCQ = [
  { link: '/mcq-2022-R', text: 'MCQ 2022.R' },
  { link: '/mcq-2022-1', text: 'MCQ 2022.1' },
  { link: '/mcq-2021-2', text: 'MCQ 2021.2' },
  { link: '/mcq-2021-1', text: 'MCQ 2021.1' },
  { link: '/mcq-2020-2', text: 'MCQ 2020.2' },
  { link: '/mcq-2020-1', text: 'MCQ 2020.1' },
  { link: '/mcq-2019-2', text: 'MCQ 2019.2' },
  { link: '/mcq-2019-1', text: 'MCQ 2019.1' },
  { link: '/mcq-2018-2', text: 'MCQ 2018.2' },
  { link: '/mcq-2018-1', text: 'MCQ 2018.1' },
  { link: '/mcq-2017-2', text: 'MCQ 2017.2' },
  { link: '/mcq-2017-1', text: 'MCQ 2017.1' },
]
const LINKS_SAQ = [
  { link: '/saq-2022-1', text: 'SAQ 2022.1' },
  { link: '/saq-2021-2', text: 'SAQ 2021.2' },
  { link: '/saq-2021-1', text: 'SAQ 2021.1' },
  { link: '/saq-2020-2', text: 'SAQ 2020.2' },
  { link: '/saq-2020-1', text: 'SAQ 2020.1' },
  { link: '/saq-2019-2', text: 'SAQ 2019.2' },
  { link: '/saq-2019-1', text: 'SAQ 2019.1' },
  { link: '/saq-2018-2', text: 'SAQ 2018.2' },
  { link: '/saq-2018-1', text: 'SAQ 2018.1' },
  { link: '/saq-2017-2', text: 'SAQ 2017.2' },
  { link: '/saq-2017-1', text: 'SAQ 2017.1' },
  { link: '/saq-2016-2', text: 'SAQ 2016.2' },
  { link: '/saq-2016-1', text: 'SAQ 2016.1' },
]
const LINKS_VIVA = [
  { link: '/viva-2021-2', text: 'VIVA 2021.2' },
  { link: '/viva-2021-1', text: 'VIVA 2021.1' },
  { link: '/viva-2020-2', text: 'VIVA 2020.2' },
  { link: '/viva-2020-1', text: 'VIVA 2020.1' },
  { link: '/viva-2019-2', text: 'VIVA 2019.2' },
  { link: '/viva-2019-1', text: 'VIVA 2019.1' },
  { link: '/viva-2018-2', text: 'VIVA 2018.2' },
  { link: '/viva-2018-1', text: 'VIVA 2018.1' },
  { link: '/viva-2017-2', text: 'VIVA 2017.2' },
  { link: '/viva-2017-1', text: 'VIVA 2017.1' },
  { link: '/viva-2016-2', text: 'VIVA 2016.2' },
  { link: '/viva-2016-1', text: 'VIVA 2016.1' },
]

interface Props {
  pageContext: {
    lastDeployed: string;
  };
}
interface State {
  isDarkMode: boolean;
}

class Exam extends React.Component<Props, State> {
  render() {
    const date = moment('02/09/2022', 'DD/MM/YYYY');
    const theme = getTheme();

    return (
      <Layout>
        <SEO title="Exam" />
        <Subheader crumbs={[]} />
        <h1>Exam</h1>
        <p>{date.format('dddd Do MMMM YYYY')}</p>

        <Row>
          <Link to="/m">
            <Button variant="contained" color="secondary">Memorize</Button>
          </Link>
          <Link to="/verbs">
            <Button variant="contained" color="secondary">Verbs</Button>
          </Link>
        </Row>

        <Row>
          {makeLinks(LINKS_MCQ)}
        </Row>

        <Row>
          <Link to="/saq-all">
            <Button variant="contained" color="primary">SAQs</Button>
          </Link>
        </Row>

        <Row>
          {makeLinks(LINKS_SAQ)}
        </Row>

        <Row>
          {makeLinks(LINKS_VIVA)}
        </Row>


        <Row>
          <p>Time until exam:</p>
          <Counter date={date} theme={theme}/>
        </Row>
      </Layout>
    );
  }
}

export default Exam;
